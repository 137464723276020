<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <slot name="header">
        <p class="modal-card-title is-size-5">
          {{ $t("preferences") }}
        </p>
      </slot>
    </header>
    <section class="modal-card-body">
      <!-- <b-field
          :label="$t('table.col-options.number-of-rows')"
          position="is-centered"
        >
          <b-numberinput
            controls-position="compact"
            min="1"
            v-model="perPage"
          ></b-numberinput>
        </b-field> -->
      <!-- <hr /> -->
      <slot name="beforeTable">
        <p class="is-size-5">
          {{ $t("table.col-options.col-filter-title") }}
        </p>
      </slot>
      <b-table
        :columns="filterTableColumns"
        :data="newData"
        :row-class="(row, index) => 'is-clickable'"
        hoverable
        checkable
        :checked-rows="checkedRows"
        :mobile-cards="false"
        :draggable="draggable"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
        @check="toggleVisibility"
        @check-all="toggleVisibilityAll"
      >
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <slot name="footer"></slot>
      <button class="button" type="button" @click="$parent.close()">
        <b-icon icon="close"></b-icon>
        <span>{{ $t("buttons.close") }}</span>
      </button>
      <!-- <button class="button is-primary" @click="saveTablePreferences">
        {{ $t("table.col-options.button.save") }}
      </button> -->
    </footer>
  </div>
</template>

<script>
export default {
  name: "TableColumnFilter",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    // perPageOption: {
    //   type: Number,
    //   default: 50,
    // },
  },
  data() {
    return {
      newData: [...this.data],
      filterTableColumns: [...this.columns],
      // checkedRows: [],
      // perPage: perPageOption,
      draggingRow: null,
      draggingRowIndex: null,
    };
  },
  watch: {
    // perPage: function (newValue) {
    //   this.$emit("update:perPageOption", newValue);
    // },
    data: function (newValue) {
      // console.log("NEWDATA", newValue);
      this.newData = newValue;
    },
  },
  computed: {
    checkedRows: function () {
      const asd = this.data.filter((obj) => obj.visible);
      // console.log("CHECKED", asd);
      return asd;
    },
  },
  methods: {
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      this.newData.splice(
        droppedOnRowIndex,
        0,
        ...this.newData.splice(this.draggingRowIndex, 1)
      );
      this.$emit(
        "order-change",
        this.newData,
        this.draggingRowIndex,
        droppedOnRowIndex
      );
    },
    /**
     * Callback method used by the table component to identify if a row is checked or not.
     */
    isVisible(checkedListItem, row) {
      checkedListItem.name === row.name && row.visible;
      return row.visible;
    },
    /**
     * Save in the local storage the table preferences for this document class
     */
    saveTablePreferences() {
      // let classPreferences;
      // if (localStorage.classPreferences) {
      //   classPreferences = JSON.parse(localStorage.classPreferences);
      // } else {
      //   classPreferences = {};
      //   classPreferences[this.classId] = {};
      // }
      // classPreferences[
      //   this.classId
      // ].newData = this.newData;
      // localStorage.classPreferences = JSON.stringify(classPreferences);
    },
    /**
     * Hide/Display a single column of the table
     */
    toggleVisibility(checkedList, row) {
      if (row) {
        const rowIndex = this.newData.indexOf(row);
        if (rowIndex >= 0) {
          this.newData[rowIndex].visible = this.newData[rowIndex].visible
            ? false
            : true;
          this.$emit("toggleVisibility", checkedList, row);
        } else {
          this.$buefy.toast.open({
            message: this.$t("error.indexOfSelectedRow"),
            type: "is-danger",
          });
        }
      }
    },
    /**
     * Hide/Display all columns of the table
     */
    toggleVisibilityAll(checkedList) {
      const rowNum = this.newData.length;
      if (checkedList && checkedList.length === rowNum) {
        this.newData.forEach((el) => {
          el.visible = true;
        });
      } else if (checkedList.length === 0) {
        this.newData.forEach((el) => {
          el.visible = false;
        });
      }
      this.$emit("toggleVisibilityAll", checkedList);
    },
  },
};
</script>

<style></style>
